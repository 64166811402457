import initInputFields from './modules/input-fields'
import initNavbar from './modules/navbar'

initNavbar()
initInputFields()


const observer = new IntersectionObserver(entries => {
    console.log(entries)
    entries.forEach(entry => {
      const intersecting = entry.isIntersecting
      const section = entry.target.dataset.section;
      const targetNav = document.querySelector( `[data-section-link=${section}]`);

      if(intersecting) {
        targetNav.classList.add("is-active");
      } else {
        targetNav.classList.remove("is-active");
      }
    })
  })
  
const hoeWerktHet = document.querySelectorAll(`[data-section]`);
hoeWerktHet.forEach(section => observer.observe(section));
