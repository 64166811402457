/**
 * Initialize navbar.
 */
const init = () => {
  let scheduledPageScrollRaf = false
  let lastPageScrollY = 0

  const navbarElement = document.querySelector('.navbar')
  const hamburgerElement = document.querySelector('.navbar .hamburger-icon')

  // Exit if no navbar exists on the page
  if (!navbarElement) return

  /**
   * Handler which will be executed on page scroll
   */
  const handlePageScroll = () => {
    // // Exit if an update is already scheduled
    if (scheduledPageScrollRaf) return
    // console.log('update');

    // Set variable to check if an update is already scheduled in the future
    scheduledPageScrollRaf = true

    // Schedule update using request animation frame to improve performance
    requestAnimationFrame(() => {
      const newPageScrollY = window.scrollY

      // Add scroll class on body if scrolled down
      if (window.scrollY > 0) {
        document.body.classList.add('page-is-scrolled-down')
      } else {
        document.body.classList.remove('page-is-scrolled-down')
      }

      // Store new scroll position
      lastPageScrollY = newPageScrollY
      scheduledPageScrollRaf = false
    })
  }

  // Collapse navbar on scroll
  window.addEventListener('scroll', handlePageScroll, { passive: true })

  // Set nav open state on hamburger icon click
  if (hamburgerElement) {
    hamburgerElement.addEventListener('click', () => {
      document.body.classList.toggle('nav-is-open')
    })
  }
}

export default init
