/**
 * Initialize text fields.
 */
const init = () => {
  const textFieldElements = document.querySelectorAll('.text-field');
  
  // Add text field event listeners
  textFieldElements.forEach(fieldElement => {
    const inputElement = fieldElement.querySelector('input, textarea');
  
    if (inputElement) {
      // Add focus class based on input focus
      inputElement.addEventListener('focus', () => {
        fieldElement.classList.add('is-active');
      });
      
      // Remove focus class based on input focus
      inputElement.addEventListener('blur', () => {
        fieldElement.classList.remove('is-active');
  
        // Add class if the field is not empty
        if (inputElement.value) {
          fieldElement.classList.add('has-value');
        } else {
          fieldElement.classList.remove('has-value');
        }
      });
    }
  });
};

export default init;